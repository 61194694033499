import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const ContainerCategoires = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const LinkCategory = styled(Link)`
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.35rem 1.25rem;
  border-radius: 50px;
  margin: 0.45rem 0.5rem;

  ${props => css`
    color: ${props.theme.textLight};
    background-color: ${props.active === 'true'
      ? props.theme.green
      : props.theme.primary};
  `};
`

const Subtitle = styled.h2`
  font-size: 2rem;
  color: ${props => props.theme.primary};
  text-align: center;
  margin-top: 3rem;
`

export { ContainerCategoires, LinkCategory, Subtitle }
