import React from 'react'
import { navigate } from 'gatsby'
import Media from 'react-media'

import Carousel from '../Carousel'

import {
  CardCellNews,
  CardImage,
  CardDescription,
} from '../Sections/SectionHomeNews.styled'
import { GradientContainer } from './RelatedNews.styled'

const RelatedNews = ({ relatedNews }) => (
  <div style={{ position: 'relative ', margin: '2rem 0' }}>
    <GradientContainer />
    <Media query="(max-width: 37.5em)">
      {matches => {
        return (
          <Carousel slidesPerView={matches ? 1 : 3}>
            {relatedNews.map(({ node }) => (
              <CardCellNews
                key={node.slug}
                onClick={() => navigate(`/noticias/${node.slug}`)}
              >
                <CardImage alt={node.title} fluid={node.heroImage.fluid} />
                <CardDescription>
                  <h3>{node.title}</h3>
                  <span>{node.publishDate}</span>
                </CardDescription>
              </CardCellNews>
            ))}
          </Carousel>
        )
      }}
    </Media>
  </div>
)

export default RelatedNews
