import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { BP_SMALL, BP_SMALLEST } from '../../styles/theme'

const ContainerCardsNews = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin-top: 2rem auto;
  margin: 0 auto;

  @media only screen and (max-width: ${BP_SMALL}) {
    justify-content: center;
  }
`

const CardCellNews = styled.div`
  width: 30%;
  max-width: 400px;
  max-height: 350px;
  flex-direction: column;
  display: flex;
  margin: 1rem 0;
  box-shadow: none;
  transition: box-shadow 250ms ease-in-out;
  border-radius: 2px;
  cursor: pointer;

  @media only screen and (max-width: ${BP_SMALL}) {
    width: 100%;
  }

  &:hover {
    box-shadow: var(--card-shadow);
  }

  ${props =>
    props.horizontal &&
    css`
      width: 100%;
      flex-direction: row;
      max-width: 1100px;
      min-height: 200px;
      margin: 1.5rem auto;
      &:hover {
        box-shadow: var(--card-shadow);
      }
      & > ${CardDescription} {
        width: 100%;
      }
      @media only screen and (max-width: ${BP_SMALL}) {
        min-width: 100px;
        flex-direction: column;
      }
    `}

  ${props =>
    props.active === 0 &&
    css`
      width: 100%;
      max-width: none;
    `}
`

const CardDescription = styled.div`
  padding: 0.75rem 1rem;
  & > h3 {
    margin: 0;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  & > span {
    display: block;
    text-align: right;
    font-size: small;
    font-weight: 100;
  }
`

const CardImage = styled(Img)`
  width: 100%;
  border-radius: 2px 2px 0 0;
  ${props =>
    props.horizontal &&
    css`
      width: 20%;
      min-width: 220px;
      @media only screen and (max-width: ${BP_SMALLEST}) {
        width: 100%;
        max-height: 140px;
      }
    `}
`

const MainSectionContainer = styled.div`
  display: flex;
  @media only screen and (max-width: ${BP_SMALL}) {
    display: block;
  }
`

const BannerImg = styled.img`
  width: 240px;
  @media only screen and (max-width: ${BP_SMALL}) {
    width: 100%;
  }
`

export {
  ContainerCardsNews,
  CardCellNews,
  CardDescription,
  CardImage,
  MainSectionContainer,
  BannerImg,
}
