import styled from 'styled-components'
import hex2rgba from '../../utils/hex2rgba'
import { BP_SMALLEST } from '../../styles/theme'

const ShareButton = styled.a`
  color: ${props => props.theme.textLight};
  background-color: ${props => props.color};
  border-radius: 20px;
  padding: 2px 2rem;
  width: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 0 0.5rem;
  &:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  &:last-child {
    margin-left: 0.5rem;
    margin-right: 0;
  }
  &:hover {
    background-color: ${props => hex2rgba(props.color, 0.8)};
  }

  @media only screen and (max-width: ${BP_SMALLEST}) {
    width: 60px;
  }
`

export { ShareButton }
