export const links = [
  {
    prefix: 'https://www.facebook.com/sharer/sharer.php?u=',
    icon: 'facebook-f',
    color: '#3a549e',
  },
  {
    prefix: 'https://twitter.com/home?status=',
    icon: 'twitter',
    color: '#1c9ff1',
  },
  {
    prefix: 'https://wa.me/?text=',
    icon: 'whatsapp',
    color: '#25d267',
  },
]
