import styled from 'styled-components'

const BackgroundImage = styled.div`
  height: 140px;
  min-width: 140px;
  background-color: ${props => props.theme.primaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  border-radius: 100px;
  font-size: 2rem;
  color: ${props => props.theme.textLight};
`

export { BackgroundImage }
