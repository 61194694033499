import React from 'react'
import { BackgroundImage } from './Imageinitials.styled'

const ImageInitials = ({ name }) => {
  function initials() {
    let initialsName = ''
    const array = name
      .trim()
      .replace(/\s{2,}/g, ' ')
      .split(' ')

    for (const string of array) {
      initialsName += string.charAt(0)
    }
    return initialsName
  }

  return (
    <BackgroundImage>
      <h2>{initials()}</h2>
    </BackgroundImage>
  )
}

export default ImageInitials
