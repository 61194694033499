import styled from 'styled-components'
import { BP_SMALLEST, BP_SMALL } from '../styles/theme'

import backgroundImage from '../images/news_post_background.png'

const HeaderNewsPost = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  background-image: url(${backgroundImage});
  background-size: 100% 550px;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${BP_SMALLEST}) {
    background-size: 200% 500px;
  }
`

const ContainerAuthor = styled.div`
  float: right;
  display: flex;
  align-items: flex-start;
  min-width: 320px;
  margin: 0 1rem;
`
const AuthorDescription = styled.div`
  position: relative;
  right: 3rem;
  background-color: white;
  z-index: 1;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 3.5rem;
  min-height: 80px;
  max-width: 270px;
  bottom: -45px;
`

const RichContent = styled.div`
  margin-bottom: 5rem;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  text-align: justify;
  & > iframe {
    display: block;
    margin: 2rem auto;
    @media only screen and (max-width: ${BP_SMALL}) {
      width: 100%;
    }
  }
  img {
    max-width: 60%;
    display: block;
    margin: 2rem auto;
    @media only screen and (max-width: ${BP_SMALL}) {
      max-width: 100%;
    }
  }
`

export { HeaderNewsPost, ContainerAuthor, AuthorDescription, RichContent }
