import React from 'react'
import { ContainerTags, Tag } from './Tags.styled'

const Tags = ({ tags }) => (
  <ContainerTags>
    {tags && tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
  </ContainerTags>
)

export default Tags
