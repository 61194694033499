import styled from 'styled-components'
import hex2rgba from '../../utils/hex2rgba'

const Tag = styled.div`
  background-color: ${props => props.theme.orange};
  padding: 0.25rem 1rem;
  border-radius: 20px;
  margin: 0.5rem;
  color: ${props => props.theme.textLight};
  font-weight: bold;
  cursor: pointer;
  &:first-child {
    margin: 0.5rem;
    margin-left: 0;
  }
  &:hover {
    background-color: ${props => hex2rgba(props.theme.orange, 0.8)};
  }
`

const ContainerTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0;
`

export { ContainerTags, Tag }
