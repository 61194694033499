import styled from 'styled-components'
import { BP_SMALL } from '../../styles/theme'

const GradientContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 10;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 65%,
      white 88%
    ),
    linear-gradient(-90deg, rgba(255, 255, 255, 0) 65%, white 88%);

  @media only screen and (max-width: ${BP_SMALL}) {
    background-image: none;
  }
`

export { GradientContainer }
