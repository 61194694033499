import React from 'react'
import { ShareButton } from './ShareLinks.styled'
import Icon from '../Common/Icon'

import { links } from './utils'

const ShareLinks = ({ href }) => (
  <div style={{ display: 'flex', margin: '0.35rem 0' }}>
    {links.map(({ prefix, icon, color }, index) => (
      <ShareButton
        key={index}
        href={`${prefix}${href}`}
        color={color}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon={['fab', icon]} size="1x" />
      </ShareButton>
    ))}
  </div>
)

export default ShareLinks
